import React from "react";
import './About.css';

export const Leader = () => {
    return (
        <div className="directors">
        <img className="rsmtr" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2FAdil_khan.jpeg?alt=media&token=f9550ace-4b40-4d39-b9c4-2b4eddde7402"></img>
        <h4 className="ceorb">Mr.AdilKhan</h4>
        <p className="prgraph">Trained <strong>15K+</strong> Students and Have 2 Year Experience.</p>
                 
        <img className="rsmtr" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2FAbdul_raqeeb.jpeg?alt=media&token=a6238890-d259-463a-9571-59c1959a07d4"></img>
        <h4 className="ceorb">Mr. Abdul Raqeeb</h4>
        <p className="prgraph">My Role in <strong>digital marketing</strong> as an <strong>entrepreneur and a freelancer.</strong> I focus on educating and mentoring youth's mindset growth and on how to become financially independent before their graduations by providing them halal income source. Over the 6 months I have mentored <strong>40+ people</strong>, helping them learn trending skills of digital marketing in the light of Islamic Shariah For Making them successful in this world and Hereafter.</p>
        </div>
            );
    };
    
