import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import { VscHome } from "react-icons/vsc";
import { BsBook } from "react-icons/bs";
import { FaPhoneVolume } from "react-icons/fa";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { MdPersonSearch } from "react-icons/md";




function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
          <img className="logs" src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/images%2F1726220562001-logo.png?alt=media&token=8a1bac8a-01bf-45c1-81f7-bbb6e2437754"></img>     
            
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/home"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <VscHome />Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                 <VscWorkspaceUnknown />About Us

              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="courses"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <BsBook />Courses
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="topleader"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <MdPersonSearch />Top Leader
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <FaPhoneVolume/>Contact Us
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                exact
                to="/Login"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                 <VscWorkspaceUnknown />Login

              </NavLink>
            </li>


          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
