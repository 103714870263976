// src/components/CourseDisplay.js
import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { db,storage } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Courses.css'; // Optional: You can create a CSS file for styles.

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  {/*Buy NOw function */}
  const [buyNow, setShowImage] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            setUploadStatus('Please select a file to upload.');
            return;
        }

        const storageRef = ref(storage, `payments/${file.name}`);
        
        try {
            // Start upload
            await uploadBytes(storageRef, file);
            setUploadStatus('Image uploaded successfully!');
        } catch (error) {
            setUploadStatus('Upload failed. Please try again.');
            console.error('Upload error:', error);
        }
    };
    const navigate = useNavigate();

    const handleOnboardClick = () => {
        navigate('/signup'); // Redirect to the login page
    };







  
    const handleButtonClick = () => {
        setShowImage(true);
    };
    const handleCloseImage = () => {
      setShowImage(false);
  };

  useEffect(() => {
    const fetchCourses = async () => {
        const coursesSnapshot = await getDocs(collection(db, 'courses'));
      try { 
        const coursesList = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCourses(coursesList);
      } catch (error) {
        console.error("Error fetching courses: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  if (loading) {
    return <div className="loading">Loading courses...</div>;
  }
  const data = [
    { id: 1, name: 'MINI PACKAGE', price: 599, topic: 'Topic: Work From Home' }
];
  const data1 = [
    { id: 2, name: 'TAJ PREMIUM PACKAGE', price: 1299, topic: 'Topic: Facebook Mastry' }
  ];
  const data2 = [
    { name: 'DATABASE PACKAGE', price: 2499, topic: 'Topic: Affiliate Marketing Mastry' }
  ];
  const data3 = [
    { name: 'MASTRY PACKAGE', price: 4399, topic: 'Topic: How to Earn Money From Instagram' }
  ];
  const data4 = [
    { name: 'MARKETING PACKAGE', price: 7499, topic: 'Topic: All About Digital Marketing' }
  ];
  const data5 = [
    { name: 'SUPER MASTRY PACKAGE', price: 12999, topic: 'Topic: SaS of Affiliate' }
  ];
  const data6 = [
    { name: 'BRANDING PACKAGE', price: 24999, topic: 'Topic: How to Become a Sales Expert' }
  ];
  return (
    <div className="course-display">
    <h3><b>Our Course Packages</b></h3>
    <ul className='crs'>
    {data.map(user => (
                        <li  key={user.id} className='course-item'>
                            
                            <h2 className='co_name'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean" src='./img/mini.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div>                
                    </div>
                    </div>
       )}                   
      </li>
       ))}
    </ul>

    <ul className='crs'>
    {data1.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/5.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>

    <ul className='crs'>
    {data2.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/2.png'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>
    <ul className='crs'>
    {data3.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/4.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>

    <ul className='crs'>
    {data4.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/3.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>

    <ul className='crs'>
    {data5.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/6.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>

    <ul className='crs'>
    {data6.map(user => (
                        <li  key={user.id} className='course-item'>
                            <h2 className='co_name1'>{user.name}</h2>
                            <p>{user.topic}</p>
                            <img className="clean1" src='./img/1.jpg'></img>
                            <p className='bolding'><b>{user.price}/-</b></p>
                            <button className='buy' onClick={handleButtonClick}>Buy Now</button>
          {buyNow && (
                <div style={{ marginTop: '20px' }}>
                    <img className='byiimag'
                        src="https://firebasestorage.googleapis.com/v0/b/taj-millionaire.appspot.com/o/course_images%2FBARCODE.jpg?alt=media&token=1a0c960e-f6af-490a-b99b-18601d1c8fbe" // Replace with your image URL
                        alt="Displayed"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                     <div style={{ marginTop: '10px' }}>
                        <button onClick={handleCloseImage}>
                            Close Image
                        </button>
                        <h4>UPI ID:9669132900-3@ibl</h4>
                        <h2>Account Details</h2>
                        <p>Account Holder Name : <strong>Jameel Khan</strong></p>
                        <p>Account No : <strong>924010009926242</strong></p>
                        <p>IFSC : <strong>UTBI0004460</strong></p>
                        <p>Bank : Axis Bank</p>
                        <Link to="/signup"><button  className='onboard_btn' onClick={handleOnboardClick}>Click to Create ID</button></Link>
                        <p className='note'>Upload Payment Screenshot</p>
                        <div style={{ textAlign: 'center', margin: '20px' }}>
            {/* Button to trigger file input */}
            <button className='upl'
                onClick={() => document.getElementById('fileInput').click()}
                style={{ padding: '10px 20px', cursor: 'pointer', color: 'red' }}
            >
                Click to Upload Screenshot
            </button>
            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }} // Hide the file input
            />
            {uploadStatus && <p>{uploadStatus}</p>}
        </div> 
    </div>
    </div>          
    )}                  
    </li>
    ))}
    </ul>

        
  </div>
  );
};
export default Courses;
